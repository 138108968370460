import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
     
<path d="M4768 9053 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6988 9053 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3357 7752 l-967 -967 0 -1290 0 -1290 968 -968 967 -967 1287 0
1287 0 970 970 971 970 0 1283 0 1282 -972 973 -973 972 -1285 0 -1285 0 -968
-968z m3755 385 c178 -177 341 -336 363 -352 58 -42 160 -148 217 -227 27 -37
53 -68 58 -68 4 0 193 -184 419 -409 l410 -409 0 -1178 -1 -1179 -311 -312
c-171 -172 -314 -310 -319 -307 -4 3 -8 292 -9 642 0 350 -4 645 -7 654 -7 17
-8 17 -19 -1 -10 -15 -9 -20 2 -25 13 -5 15 -57 15 -341 l0 -335 -30 -7 c-38
-7 -48 -22 -27 -38 20 -14 25 -101 9 -132 -19 -35 -15 -53 12 -53 13 0 27 6
30 13 3 6 4 1 3 -13 -2 -22 -9 -26 -62 -36 -47 -9 -53 -12 -26 -13 19 0 31 -4
28 -7 -3 -4 -2 -15 4 -25 6 -12 6 -25 0 -37 -12 -22 -4 -34 29 -49 23 -10 20
-11 -33 -18 -52 -7 -91 -25 -53 -25 9 0 16 -6 16 -12 0 -9 3 -8 10 2 9 15 25
7 25 -13 0 -7 12 -13 28 -15 40 -5 33 -27 -9 -30 -20 -2 -46 -9 -57 -17 -18
-13 -19 -15 -4 -21 9 -3 17 -12 17 -20 0 -8 4 -14 10 -14 5 0 17 -14 26 -32
15 -29 15 -32 -2 -42 -11 -5 -24 -11 -31 -12 -6 -1 -19 -7 -30 -13 -16 -9 -15
-10 10 -11 40 0 36 -33 -9 -59 -19 -12 -42 -21 -50 -21 -35 0 -4 -26 42 -34
27 -5 50 -10 52 -12 2 -1 0 -13 -4 -28 -4 -19 -13 -26 -33 -27 -23 -2 -24 -2
-3 -6 27 -5 28 -18 2 -36 -11 -8 -17 -16 -14 -20 3 -3 -10 -4 -31 -1 -23 3
-34 1 -30 -6 4 -6 11 -8 16 -5 5 4 9 -1 9 -9 0 -9 5 -16 10 -16 18 0 10 -20
-21 -53 -17 -17 -39 -43 -48 -57 -14 -23 -43 -51 -120 -118 -46 -40 -203 -111
-301 -135 l-95 -24 -199 -202 -200 -201 -1185 0 -1186 0 -152 152 c-84 84
-153 156 -153 160 0 5 19 6 43 3 23 -3 35 -3 27 1 -40 15 -59 28 -54 36 3 4
-6 12 -20 18 -30 11 -35 24 -6 15 14 -5 20 -2 20 9 0 9 -7 16 -15 16 -18 0
-20 16 -2 23 6 3 2 6 -10 6 -12 1 -25 6 -28 11 -4 6 -3 10 2 9 4 -1 18 -1 31
0 26 1 30 21 5 22 -14 0 -13 3 5 11 l22 11 -21 23 -21 23 33 20 c29 18 31 21
12 21 -28 0 -38 17 -23 35 10 12 7 17 -15 25 -26 10 -27 11 -11 35 20 30 21
74 2 81 -8 3 -19 19 -25 35 -10 28 -9 29 40 46 27 10 49 20 49 24 0 3 -25 11
-56 18 -35 8 -52 16 -44 21 8 5 3 11 -16 18 l-29 10 31 11 c31 12 31 13 33 85
1 57 -2 75 -13 79 -8 3 -20 15 -26 27 -9 17 -7 22 15 30 33 13 32 18 -7 29
l-33 9 43 7 c49 9 62 23 39 40 -10 7 -17 17 -17 22 0 6 -7 16 -15 23 -18 15
-21 90 -3 90 7 0 -6 18 -27 40 -25 25 -33 40 -24 40 8 0 24 7 35 15 16 13 17
16 4 25 -13 9 -13 11 0 20 8 5 21 10 29 10 7 0 -1 13 -19 28 -35 31 -37 60 -5
79 20 12 20 13 -2 19 -28 8 -30 24 -4 24 11 0 23 5 26 10 3 6 0 10 -7 10 -9 0
-9 3 2 10 13 9 11 13 -9 29 -13 10 -19 21 -14 24 16 10 2 57 -21 69 -45 24
-52 48 -16 60 8 2 15 19 16 41 2 28 -1 37 -17 42 -32 10 -22 22 26 29 53 8 58
21 13 30 -26 5 -30 9 -18 16 13 9 13 11 0 20 -8 5 -22 10 -30 11 -9 0 -10 3
-4 5 7 3 10 14 7 24 -4 15 0 20 15 20 12 0 24 5 27 10 3 6 -3 10 -14 10 -38 0
-32 20 9 26 22 4 40 9 40 13 0 3 -14 21 -30 39 -30 33 -50 41 -50 20 0 -6 -3
-8 -6 -5 -4 4 1 18 10 32 9 14 14 31 10 37 -5 8 -10 5 -15 -8 -6 -15 -8 -12
-8 13 -1 26 4 33 30 42 27 9 30 13 18 27 -16 19 -20 57 -6 65 5 4 0 10 -12 14
-27 8 -27 21 2 29 21 5 20 7 -13 28 -19 12 -38 21 -42 20 -5 -1 -8 5 -8 13 0
23 37 55 64 55 38 0 40 18 4 25 -18 4 -36 9 -41 12 -11 7 12 43 28 43 32 0 13
20 -25 26 -32 5 -40 11 -40 28 0 25 13 48 26 47 5 -1 10 16 12 38 2 32 7 40
25 43 34 5 31 31 -5 52 -18 11 -36 19 -40 18 -5 -1 -8 7 -8 18 0 11 7 20 15
20 8 0 15 4 15 9 0 4 11 6 25 3 14 -2 25 -1 25 2 0 4 -11 11 -25 16 -29 11
-33 38 -7 46 29 9 45 19 43 28 -1 4 6 14 15 21 17 12 14 14 -21 24 -22 6 -50
11 -62 11 -15 0 -23 6 -23 17 0 9 3 13 7 10 10 -11 33 13 33 34 0 13 -7 19
-24 19 -19 0 -23 4 -19 19 3 11 10 18 16 15 7 -2 26 7 42 21 l30 24 -26 1
c-21 0 -28 6 -32 28 -3 15 -2 30 1 34 13 14 1 98 -14 98 -8 0 -14 7 -14 15 0
8 7 15 16 15 10 0 14 7 12 23 -2 14 5 29 20 41 18 14 20 21 11 32 -6 8 -15 12
-19 9 -4 -3 -10 5 -14 18 -5 18 -8 20 -16 7 -7 -11 -10 -6 -10 23 0 24 4 36
12 35 17 -4 25 57 15 117 -3 17 -10 29 -16 27 -6 -1 -11 5 -11 13 0 8 3 14 8
14 4 -1 15 -1 26 0 14 1 16 4 8 14 -17 21 -13 107 5 107 10 0 13 6 9 16 -3 9
-6 20 -6 25 0 6 -4 8 -9 5 -5 -3 -11 4 -14 15 -3 12 -10 18 -16 14 -7 -4 -11
12 -11 49 0 30 -3 61 -6 70 -4 11 0 16 15 16 14 0 19 5 15 15 -4 8 -10 12 -15
9 -5 -3 -9 4 -9 16 0 12 4 19 9 15 13 -8 24 31 16 56 -6 17 -9 19 -16 8 -6
-10 -9 -6 -9 14 0 19 5 27 18 27 16 1 16 1 0 14 -21 15 -23 27 -6 23 7 -1 14
8 16 21 2 15 10 22 25 22 17 0 19 2 7 9 -8 6 -18 7 -22 5 -4 -3 -9 3 -9 13 -1
10 -3 25 -4 33 -1 8 -3 42 -4 75 -1 43 -4 56 -11 45 -7 -11 -10 -6 -10 20 0
23 3 30 9 22 5 -9 10 3 14 33 8 52 0 98 -14 89 -5 -3 -8 27 -7 68 1 67 2 69 8
28 l7 -45 1 39 c1 23 9 44 18 51 15 11 15 14 -1 38 -9 15 -21 25 -25 22 -5 -3
-10 1 -13 7 -6 21 4 48 19 48 17 0 19 37 2 43 -16 5 -15 130 2 195 35 138 116
265 185 293 47 18 540 14 633 -5 40 -9 76 -16 80 -16 15 0 -178 82 -258 110
-106 36 -189 50 -307 50 -58 0 -93 4 -93 11 0 5 48 57 106 115 l106 104 1178
0 1179 0 323 -323z m-3472 -2574 c0 -1286 4 -2127 10 -2172 6 -41 7 -77 5 -80
-3 -3 -231 220 -505 494 l-500 500 0 1187 0 1188 488 489 c268 269 490 490
495 490 4 1 7 -943 7 -2096z m327 1495 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2
-13 2 -33 0 -45z m3960 -2910 c-2 -18 -4 -4 -4 32 0 36 2 50 4 33 2 -18 2 -48
0 -65z"/>
<path d="M6252 8039 c-369 -43 -613 -229 -751 -572 l-23 -57 30 -93 c67 -201
108 -405 139 -677 l18 -165 11 195 c26 434 96 689 249 905 115 163 318 281
560 327 78 14 673 19 735 5 64 -14 17 12 -123 69 -90 36 -162 58 -209 64 -88
11 -535 11 -636 -1z"/>
<path d="M3970 7653 c0 -22 2 -25 10 -13 13 20 13 40 0 40 -5 0 -10 -12 -10
-27z"/>
<path d="M4704 7108 c85 -134 125 -252 158 -468 19 -125 21 -191 25 -897 5
-810 -1 -1029 -37 -1237 -26 -156 -53 -243 -107 -348 -69 -136 -169 -224 -278
-246 l-40 -8 0 -64 0 -65 180 0 c170 0 183 1 224 23 51 28 52 28 111 80 99 88
180 278 216 507 30 193 36 433 31 1215 -4 648 -7 793 -21 885 -39 266 -109
447 -214 554 -64 65 -113 93 -190 110 -95 20 -97 18 -58 -41z"/>
<path d="M7922 7000 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M7010 6945 c0 -4 14 -35 30 -69 17 -33 44 -113 60 -176 35 -134 75
-222 122 -267 20 -19 84 -53 165 -87 72 -31 134 -56 138 -56 4 0 -7 17 -23 38
-49 62 -61 93 -130 332 -48 171 -155 271 -302 287 -33 3 -60 2 -60 -2z"/>
<path d="M7932 6705 c-1 -132 -7 -260 -13 -284 -12 -56 -4 -66 11 -13 6 26 9
133 7 289 l-2 248 -3 -240z"/>
<path d="M5672 5944 l-2 -382 98 -98 c178 -180 336 -278 727 -453 330 -148
417 -198 508 -295 90 -96 124 -207 113 -368 -10 -164 -60 -257 -161 -304 -49
-23 -57 -24 -337 -24 -158 0 -289 -4 -292 -8 -8 -13 52 -66 104 -93 44 -24 49
-24 397 -27 223 -2 367 1 391 8 122 32 188 143 199 330 9 173 -32 286 -147
394 -106 99 -182 141 -580 316 -448 197 -736 449 -872 762 -75 174 -125 380
-141 583 -2 22 -4 -132 -5 -341z"/>
<path d="M6770 6006 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z"/>
<path d="M6860 5966 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M7875 5100 c7 -33 24 -64 31 -57 5 4 -21 75 -30 84 -5 5 -5 -8 -1
-27z"/>
<path d="M7898 4943 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7888 4753 c17 -7 15 -21 -5 -26 -11 -3 -7 -5 10 -6 21 -1 27 4 27
19 0 14 -6 20 -22 19 -13 0 -17 -3 -10 -6z"/>
<path d="M5665 4692 c-3 -3 -5 -37 -5 -76 0 -130 -45 -536 -70 -636 -5 -19
-19 -78 -31 -130 -34 -151 -59 -220 -80 -220 -9 0 -19 5 -21 12 -6 17 -167 11
-196 -8 -43 -28 -15 -36 107 -29 64 3 121 2 127 -2 6 -4 30 -42 54 -83 115
-200 254 -336 431 -426 117 -58 334 -144 362 -143 20 1 19 2 -5 9 -52 16 -174
83 -221 123 -128 107 -214 216 -291 373 -60 119 -59 118 -91 219 -45 143 -66
271 -72 440 -8 229 16 342 88 414 34 34 28 33 184 32 l110 0 -39 21 c-132 69
-320 130 -341 110z"/>
<path d="M7885 4390 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10 -8
0 -18 -4 -21 -10z"/>
<path d="M7872 4341 c-10 -6 -6 -11 17 -19 28 -10 31 -9 31 9 0 20 -24 25 -48
10z"/>
<path d="M5378 3923 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M5440 3780 c0 -5 10 -10 23 -10 18 0 19 2 7 10 -19 13 -30 13 -30 0z"/>
<path d="M5270 3770 c-10 -7 12 -10 73 -10 51 0 87 4 87 10 0 13 -140 13 -160
0z"/>
<path d="M5446 3592 c-5 -8 -29 -12 -64 -10 -32 1 -63 -2 -71 -6 -25 -16 8
-24 80 -20 48 2 69 0 69 -8 0 -19 17 4 24 32 6 23 6 23 -5 5 -12 -19 -12 -19
-19 0 -5 13 -9 15 -14 7z"/>
<path d="M5283 3426 c-94 -10 -111 -26 -29 -26 30 0 58 -4 61 -10 11 -17 63
-12 68 8 3 12 5 10 6 -7 1 -24 1 -24 16 -5 8 10 15 26 15 35 0 18 -16 18 -137
5z"/>
<path d="M5370 3349 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5305 3340 c-28 -7 -27 -8 13 -9 23 0 42 -5 42 -10 0 -5 -19 -12 -42
-16 -31 -6 -37 -9 -20 -12 47 -9 19 -19 -42 -15 -64 4 -112 -10 -100 -29 3 -6
29 -7 62 -4 58 6 90 -4 78 -24 -4 -6 -27 -11 -52 -11 -54 0 -86 -23 -56 -39
23 -12 36 -3 22 14 -7 8 -5 12 4 12 8 0 17 -11 21 -24 8 -34 24 -25 67 38 20
30 41 59 45 64 15 17 23 44 17 60 -7 16 -13 17 -59 5z"/>
<path d="M5088 3253 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5112 3185 c-11 -13 -10 -15 5 -15 10 0 24 4 31 9 11 7 10 10 -3 15
-20 7 -17 8 -33 -9z"/>
<path d="M5168 3122 c-21 -2 -38 -7 -38 -11 0 -5 15 -7 33 -6 17 1 35 -1 38
-6 3 -5 13 -1 22 9 18 20 18 20 -55 14z"/>
<path d="M5088 3113 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5160 3079 c-14 -5 -46 -11 -72 -14 -52 -7 -66 -25 -19 -25 30 0 46
-11 34 -24 -3 -3 -12 -1 -19 5 -8 7 -14 7 -19 -1 -3 -5 -2 -10 4 -10 6 0 8 -7
5 -15 -11 -28 14 -15 68 34 55 51 60 63 18 50z"/>
<path d="M4978 2933 c-10 -2 -18 -9 -18 -14 0 -6 8 -5 20 1 11 6 20 13 20 15
0 3 -1 4 -2 4 -2 -1 -11 -3 -20 -6z"/>
<path d="M4925 2900 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M3830 8360 c-13 -9 -13 -10 0 -11 50 -5 72 -4 76 3 3 4 -4 8 -15 8
-10 0 -25 2 -33 5 -7 3 -20 0 -28 -5z"/>
<path d="M3920 8359 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7430 8360 c-9 -6 -6 -9 11 -12 30 -4 79 2 79 11 0 9 -75 10 -90 1z"/>
<path d="M7538 8359 c-19 -12 8 -18 101 -21 51 -1 95 -6 97 -10 3 -5 11 -8 19
-8 10 0 7 7 -9 23 -20 20 -32 22 -110 22 -48 0 -92 -3 -98 -6z"/>
<path d="M3678 8353 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3748 8353 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3154 7638 c-2 -3 2 -8 8 -11 16 -5 48 3 48 12 0 8 -51 8 -56 -1z"/>
<path d="M8108 7639 c-18 -11 16 -16 67 -9 l50 6 -53 5 c-30 2 -58 1 -64 -2z"/>
<path d="M8273 7643 c-68 -9 -29 -23 61 -23 52 0 106 -4 120 -10 31 -11 33 -7
7 19 -15 15 -33 19 -87 18 -38 0 -84 -2 -101 -4z"/>
<path d="M8025 7630 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2440 6910 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12
0 -21 -4 -21 -10z"/>
<path d="M8940 6910 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M9010 6911 c-32 -6 -33 -7 -11 -13 13 -4 36 -5 50 -2 14 3 47 1 74
-4 42 -8 47 -7 47 8 0 15 -9 18 -62 18 -35 -1 -79 -4 -98 -7z"/>
<path d="M8967 6188 c-15 -10 -18 -9 89 -12 50 -2 96 -6 103 -10 7 -4 11 0 11
12 0 18 -7 19 -96 18 -53 0 -101 -4 -107 -8z"/>
<path d="M8898 5472 c-47 -10 -7 -17 102 -19 99 -1 120 1 120 13 0 12 -19 14
-102 12 -57 -1 -111 -4 -120 -6z"/>
<path d="M9130 5465 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M8862 4750 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M8960 4760 c0 -6 -10 -8 -24 -4 -14 3 -33 1 -43 -5 -14 -8 -6 -10 41
-11 51 0 57 2 46 15 -15 18 -20 19 -20 5z"/>
<path d="M2265 4750 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M9012 4751 c-11 -7 -10 -10 3 -15 9 -3 20 -4 24 -2 3 2 34 1 69 -3
56 -7 62 -6 62 11 0 16 -9 18 -72 18 -40 0 -79 -4 -86 -9z"/>
<path d="M2300 4030 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M2455 4030 c-3 -6 1 -13 10 -16 19 -8 30 0 20 15 -8 14 -22 14 -30 1z"/>
<path d="M2510 4030 c0 -5 9 -14 19 -20 31 -16 36 -12 11 10 -26 23 -30 24
-30 10z"/>
<path d="M8815 4030 c-15 -6 -8 -9 28 -9 26 -1 47 4 47 9 0 12 -47 12 -75 0z"/>
<path d="M8910 4030 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8950 4030 c0 -13 18 -22 24 -11 3 5 -1 11 -9 15 -8 3 -15 1 -15 -4z"/>
<path d="M9002 4028 c5 -14 83 -29 110 -22 9 3 19 11 22 19 4 12 -7 15 -65 15
-52 0 -70 -3 -67 -12z"/>
<path d="M3165 3300 c4 -6 10 -8 15 -5 5 3 19 1 30 -5 16 -9 18 -8 14 5 -4 9
-18 15 -36 15 -17 0 -27 -4 -23 -10z"/>
<path d="M3230 3305 c0 -2 9 -9 20 -15 16 -9 18 -8 14 5 -5 13 -34 22 -34 10z"/>
<path d="M8215 3300 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M8257 3299 c15 -14 141 -15 155 -1 10 10 -4 12 -75 11 -48 -1 -84 -6
-80 -10z"/>
<path d="M3915 2580 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M3960 2582 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z"/>
<path d="M7520 2579 c0 -5 12 -9 28 -10 15 -1 45 -2 67 -4 22 -1 51 4 65 11
22 12 15 13 -67 12 -51 0 -93 -5 -93 -9z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
